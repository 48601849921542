import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import SelectMU from '@material-ui/core/Select';
import { rgba } from '@utils/Helper';
var ITEM_HEIGHT = 48;
var ITEM_PADDING_TOP = 8;
export var MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 252,
      left: '-100px !important'
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  variant: 'menu'
};
export var InputLabelWrapper = styled(InputLabel)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: ", ";\n  font-weight: 500;\n"])), function (props) {
  return props.labelColor || '#414142';
});
export var FilterTitleWrapper = styled(InputLabelWrapper)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: 8px 8px 0 18px;\n"])));
export var SearchItem = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: 12px 8px 4px 8px;\n  position: sticky;\n  top: 0px;\n  background: white;\n  z-index: 1;\n  margin-top: -8px;\n\n  svg {\n    color: #c6cacc;\n  }\n"])));
export var OptionItem = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  min-height: 40px;\n  margin-bottom: 8px;\n\n  &:hover,\n  &.selected-item {\n    background-color: #f1f3f4;\n  }\n\n  &.item-disable {\n    background-color: #e3e5e5;\n    cursor: not-allowed;\n  }\n\n  &.null-item {\n    color: #919699;\n  }\n"])));
export var SpinnerWrapper = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  position: absolute;\n  height: ", "px;\n  width: 100%;\n  background: ", ";\n"])), ITEM_HEIGHT * 4.5 - 40, rgba('#000000', 0.2));
export var SelectWrapper = styled(SelectMU)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  .placeholder-item {\n    color: #919699;\n  }\n"])));